@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~@vaultinum/app-sdk/dist/style.css";

@layer base {
  @font-face {
        font-family: "inter";
        src: url("./styles/font/inter.ttf");
    }
    html {
        font-family: "inter", sans-serif;
        scroll-behavior: smooth;
    }
    h1 {
        @apply mb-5 font-bold text-label;
    }
}
:root {
    --color-background: rgb(248, 248, 248);
    --color-primary: rgb(50, 85, 116);
    --color-primary-hover: rgb(53, 116, 151);
    --color-secondary: rgb(74, 115, 152);
    --color-secondary-hover: rgb(93, 176, 231);
    --color-dark: rgb(26, 49, 63);
    --color-dark-hover: rgb(11, 35, 49);
    --color-danger: rgb(255, 77, 79);
    --color-danger-hover: rgb(199, 20, 29);
    --color-contrast: white;
    --color-contrast-hover: rgb(227, 227, 227);
    --color-font: rgb(71, 71, 71);

    --breakpoint-mobile: 768px;

    --desktop-fontsize: 16px;
    --mobile-fontsize: 18px;

    --transition-duration: 0.3s;

    --border-color: rgb(204, 204, 204);
    --border-light-radius: 2px;
    --border: 1px solid var(--border-color);
    --box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);
    --data-entry-shadow: 0 0 0 2px rgba(67, 80, 90, 0.2);

    --font-size-small: 12px;

    --blank-bg-color: hsl(218, 33%, 95%);
}
